import React from 'react'
import '../../css/Doc.css'

// Order Review
// 訂單評價
const OrderReview = () => {

    return (
        <div>
            
        </div>
    )
}

export default OrderReview;